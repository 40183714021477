import React from 'react'
import { useEffect } from 'react'
import { Footer } from './components/Footer/Footer'
import { Header } from './components/Header/Header'
import { Main } from './components/Main/Main'
import { GlobalStyle } from './styles/global'
import 'react-toastify/dist/ReactToastify.css'
import CookieBanner from 'react-cookie-banner';
import { About } from "./components/About/About";
import { Contact } from "./components/Contact/Contact";
import { Portfolio } from "./components/Portfolio/Portfolio";
import { TermsOfUse } from './components/TermsOfUse/TermsOfUse'
import { PrivacyPolicy } from './components/PrivacyPolicy/PrivacyPolicy'
import { Cookies } from './components/Cookies/Cookies'

import { ModalProvider } from 'styled-react-modal'

export function App() {
  return (
    <>
      <GlobalStyle></GlobalStyle>
      <Header></Header>
      <Main></Main>
      <Footer></Footer>
      <div>
        <CookieBanner
          message="We value your privacy. We use only strictly necessary cookies that enable the website to function. Without them, it will either not work at all or will not work properly"
          onAccept={() => { }}
          cookie="user-has-accepted-cookies" />
      </div>
    </>
  )
}

export function AboutPage() {
  return (
    <>
      <GlobalStyle></GlobalStyle>
      <div>
        <CookieBanner
          message="We value your privacy. We use only strictly necessary cookies that enable the website to function. Without them, it will either not work at all or will not work properly"
          onAccept={() => { }}
          cookie="user-has-accepted-cookies" />
      </div>
      <Header></Header>
      <About></About>
      <Footer></Footer>
    </>
  )
}

export function ContactPage() {
  return (
    <>
      <GlobalStyle></GlobalStyle>
      <div>
        <CookieBanner
          message="We value your privacy. We use only strictly necessary cookies that enable the website to function. Without them, it will either not work at all or will not work properly"
          onAccept={() => { }}
          cookie="user-has-accepted-cookies" />
      </div>
      <Header></Header>
      <Contact></Contact>
      <Footer></Footer>
    </>
  )
}

export function PortfolioPage() {
  return (
    <>
      <GlobalStyle></GlobalStyle>
      <div>
        <CookieBanner
          message="We value your privacy. We use only strictly necessary cookies that enable the website to function. Without them, it will either not work at all or will not work properly"
          onAccept={() => { }}
          cookie="user-has-accepted-cookies" />
      </div>
      <Header></Header>
      <Portfolio></Portfolio>
      <Footer></Footer>
    </>
  )
}

export function TermsOfUsePage() {
  return (
    <>
      <GlobalStyle></GlobalStyle>
      <div>
        <CookieBanner
          message="We value your privacy. We use only strictly necessary cookies that enable the website to function. Without them, it will either not work at all or will not work properly"
          onAccept={() => { }}
          cookie="user-has-accepted-cookies" />
      </div>
      <Header></Header>
      <TermsOfUse></TermsOfUse>
      <Footer></Footer>
    </>
  )
}

export function PrivacyPolicyPage() {
  return (
    <>
      <GlobalStyle></GlobalStyle>
      <div>
        <CookieBanner
          message="We value your privacy. We use only strictly necessary cookies that enable the website to function. Without them, it will either not work at all or will not work properly"
          onAccept={() => { }}
          cookie="user-has-accepted-cookies" />
      </div>
      <Header></Header>
      <PrivacyPolicy></PrivacyPolicy>
      <Footer></Footer>
    </>
  )
}

export function CookiesPage() {
  return (
    <>
      <GlobalStyle></GlobalStyle>
      <div>
        <CookieBanner
          message="We value your privacy. We use only strictly necessary cookies that enable the website to function. Without them, it will either not work at all or will not work properly"
          onAccept={() => { }}
          cookie="user-has-accepted-cookies" />
      </div>
      <Header></Header>
      <Cookies></Cookies>
      <Footer></Footer>
    </>
  )
}