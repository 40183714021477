import { Container } from './styles'
import { NavHashLink, HashLink } from 'react-router-hash-link'
import { Component, useContext, useState } from 'react'
import { useTranslation } from "react-i18next";
import Flag from 'react-world-flags'
import nextGenisLogo from '../../assets/nextGenisLogoFull.png'

export function Header() {
  const [isActive, setActive] = useState(false)

  function closeMenu() {
    setActive(false)
  }

  const { t } = useTranslation();

  const { i18n } = useTranslation();

  // let flagContent = '';

  // class CustomSelect extends Component {
  //   render() {
  //     const handleChange = () => {
  //       // this.setState({selectedValue: e.target.value})
  //       i18n.language == 'en' ? flagContent = 'US' : flagContent = 'GR'
  //     }
  //     return (
  //     <div className="select" id="switch">
  //       <select
  //           value={i18n.language}
  //           // onChange={() => handleChange()}
  //           onChange={(e) => {i18n.changeLanguage(e.target.value); handleChange()}}>
  //       <Flag code={ flagContent } height="16" />
  //       <option value="en">
  //             EN
  //           </option>
  //           <option value="el">
  //             ΕΛ
  //           </option>
  //        </select>
  //        </div>
  //     )
  //   }
  // }

  return (
    <Container className="header-fixed">
      <HashLink smooth to="/#home" className="logo">
        <img src={nextGenisLogo} className='c-logo' alt='company logo'></img>
        <span>&nbsp;</span>
      </HashLink>

      <div className="flex"></div>
      <nav className={isActive ? 'active' : ''}>
        <NavHashLink smooth to="/#about-nextgenis" onClick={closeMenu}>
          {t("nav_theFirm")}
        </NavHashLink>
        <NavHashLink smooth to="/about" onClick={closeMenu}>
          {t("nav_ourPhilosophy")}
        </NavHashLink>
        <NavHashLink smooth to="/portfolio" onClick={closeMenu}>
          {t("nav_services")}
        </NavHashLink>
        <NavHashLink smooth to="/contact" onClick={closeMenu}>
          {t("nav_ourTeamContact")}
        </NavHashLink>  
        {/* 
        {function handleFlag(){
            if(i18n.language == 'en') { return <div><Flag code={ 'US' } height="16" /></div> } else { return <div><Flag code={ 'GR' } height="16" /></div> }
          }} */}

        <div className="select" id="switch" >
        <Flag code={ i18n.language } height="24" className='flag' />
          <select
            value={i18n.language}
            onChange={(e) =>
              i18n.changeLanguage(e.target.value)
            }
          >
            <option value="GB">
              GB/UK
            </option>
            <option value="GR">
              ΕΛ
            </option>
          </select>
        </div>
      </nav>
      {console.log('Changed language to:', i18n.language)}
      <div
        aria-expanded={isActive ? 'true' : 'false'}
        aria-haspopup="true"
        aria-label={isActive ? 'Fechar menu' : 'Abrir menu'}
        className={isActive ? 'menu active' : 'menu'}
        onClick={() => {
          setActive(!isActive)
        }}
      ></div>

      <div>
        &nbsp;&nbsp;
      </div>
    </Container>
  )
}