import styled from "styled-components";

export const Container = styled.section`
  margin-top: 10rem;
  margin-bottom: 10rem;

  h2{
    text-align: center;
    font-size: 4rem;
    margin-bottom: 10rem;
    color:var(--blue);
    text-shadow: #000000 0.025em 0.025em 0.025em;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 5px;
  }

  .projects{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    gap: 2rem;
    padding: 2rem;
    overflow: hidden;

    .project{
      padding: 2rem 1.8rem;
      background-color: #016fb9;
      border-radius: 1.2rem;
      display: flex;
      flex-direction: column;
      height: 100%;
      color: #FFF;
      background-color: 2s ease-out;
      transition-timing-function: linear;
      &:hover{
        transition: 0.5s;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        }
      }

      header{
        display: flex;
        //align-items: center;
        justify-content: space-between;
        height: 20%;
        padding: 15px 15px 15px 0px;
        margin-bottom: 2rem;
        align-items: center;
      }
      
      .project > header {
        font-size: 2rem;
        text-align: center;
      }

      .body {
        line-height: 1.8;
      }

      p{
        letter-spacing: 0.12rem;
        margin-bottom: 2rem;
        font-size: 1.8rem;
        text-align: justify;
        margin-left: 1rem;
        a{
          color: #FFFF;
          border-bottom: 1px solid var(--green);
          transition: color 0.25s;
          &:hover{
            color: var(--green);
          }
        }
      }
    }
  }

  .content:hover {
    background: #ff0057;

  }
  
  @media (max-width: 960px){
    .projects{
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: 740px){
    .project{
      padding: 2rem 1.8rem;
      grid-template-columns: 1fr;
      }
  }

  @media only screen and (max-width: 700px){
    .projects{
      grid-template-columns: 1fr;
      margin-bottom: 5rem;
    }
  }

  a{
    color: #fff;
    font-weight: bold;
  }

  .noBreakSpan{
    white-space:nowrap;
  }
  
  .noBreakSpan {
    white-space:nowrap;
  }
`