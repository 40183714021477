import { Container } from "./styles";
import { useTranslation } from "react-i18next";
import { Parallax, Background } from 'react-parallax';
import ScrollAnimation from "react-animate-on-scroll";

export function About() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  return (
    <>
      <Parallax strength={300}>
        <Background className="heroBackground">
          <img src={process.env.PUBLIC_URL + '/bg_about.jpg'} style={{ height: "100vh", width: "100vw", objectFit: "cover" }} alt="Building with bright sky" />
        </Background>

        <Container>
          <div className="about">
            <div className="projects">
              <header>
                {/* <h3>
                {t("portfolio_box1_1")}
              </h3> */}
              </header>

              <div className="project">
                <h2>{t("about_moto")}</h2>
                <ScrollAnimation animateIn="fadeInRight" delay={0.1 * 800}>
                  <p>{t("about_text1")}</p>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInRight" delay={0.2 * 800}>
                  <p>{t("about_text2")}</p>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInRight" delay={0.3 * 800}>
                  <p>{t("about_text3")}</p>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInRight" delay={0.4 * 800}>
                  <p>{t("about_text4")}</p>
                </ScrollAnimation>
              </div>



            </div>
          </div>
          <div className="about-image">
            {/* <img src={ amundiLogo } alt="Amundi Corporation" /> */}
          </div>
          <div></div>
        </Container>
      </Parallax>
    </>
  )
}
