import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root{
    --pink: #E31F71;
    --black: #212121;
    --green: #23ce6b;
    --blue: #016fb9;
    --light-gray: #d3d3d3;
    scroll-padding-top: 10rem;

    &.light{

      body{
        transition: 0.5s;
        background-color: #f5f5f5;
        color: var(--black);
      }

    .logo{ color: var(--black); }

      header.header-fixed{
        transition: 0.5s;
        background-color: #f5f5f550;
        a{
          transition: 0.5s;
          color: black;
        }
        .menu,.menu:before, .menu:after{ background-color: var(--black); }
        .menu.active{ background-color: rgba(555,555,555,0); }
      }

      .bgimg{ transform: translateY(-20%); }

      footer.footer{
        transition: 0.5s;
        background-color: rgba(0,0,0,0.1);
        color: var(--black);
      }

      form{
        input,textarea{
          transition: 0.5s;
          border: solid 1px var(--black);
          color: var(--black);
          &::placeholder{
            transition: 0.5s;
            color: var(--black);
          }
        }
      }
    }
  }

  #root > .custom-bg::before { z-index: -9999; }

  ul, li {
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding:0;
  }

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html{
    font-size: 62.5%;
  }

  body{
    font-size: 1.6rem;
    -webkit-font-smoothing: antialiased;
    background-color: var(--black);
    color: #FFFF;
  }

  body, input, textarea, button{
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 400;
  }

  a{
    color: var(--black);
    font-weight: bold;
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
  }

  button, .button{
    border: none;
    cursor: pointer;
    background-color: var(--green);
    color: #FFFF;
    border-radius: 2rem;
    font-weight: 500;
    transition: filter 0.25s;
    &:hover{
      filter: brightness(0.8);
    }
  }

  button:disabled, .button:disabled{
    filter: brightness(0.8);
    cursor: not-allowed;
  }

  .logo{
    font-size: 3rem;
    color: #FFFF;
    &::first-letter{
      color: var(--green);
    }
  }

  .ReactModal__Overlay {
    -webkit-perspective: 600;
    perspective: 600;
    opacity: 0;
    margin-top: 15rem;
    height: 60vh;
    width: 80vh;
  }
  
  .ReactModal__Overlay--after-open {
    opacity: 1;
    transition: opacity 150ms ease-out;
  }
  
  .ReactModal__Content {
    -webkit-transform: scale(0.5) rotateX(-30deg);
    transform: scale(0.5) rotateX(-30deg);
  }
  
  .ReactModal__Content--after-open {
    -webkit-transform: scale(1) rotateX(0deg);
    transform: scale(1) rotateX(0deg);
    transition: all 150ms ease-in;
  }
  
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
  
  .ReactModal__Content--before-close {
    -webkit-transform: scale(0.5) rotateX(30deg);
    transform: scale(0.5) rotateX(30deg);
    transition: all 150ms ease-in;
  }
  
  .ReactModal__Body--open,
  .ReactModal__Html--open {
    overflow: hidden;
  }
`