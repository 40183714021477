import { Container } from "./styles";
import { useTranslation } from "react-i18next";

export function PrivacyPolicy() {
  const { t } = useTranslation();
  return (
    <Container id="PrivacyPolicy">
      <div className="about-text">
        <h2>{t("pp_title")}</h2>
        <h3>{t("pp_sub_title")}</h3>
        <p>
          {t("pp_preface")}
        </p>
        <p>
          {t("pp_preamble")}
        </p>
        <p>
          {t("pp_txt_1")}
        </p>
        <p>
          {t("pp_txt_2")}
        </p>
        <p>
          {t("pp_txt_3")}
        </p>
        <p>
          {t("pp_txt_4")}
        </p>
        <p>
          {t("pp_txt_5")}
        </p>
        <p>
          {t("pp_txt_6")}
        </p>
        <p>
          {t("pp_txt_7")}
        </p>
      </div>
    </Container>
  )
}
