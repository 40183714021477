import styled from "styled-components";

export const Container = styled.footer`
  background-color: #2b2b2b;
  padding: 3rem 15rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  a:link, a:visited {
    color: #000000;
    text-decoration: none;
  }

  .logo{
    font-size: 2.8rem;
  }

  .c-logo{
    height: 5rem;
    padding-right: 2rem;
  }
  
  .linkedin-logo{
    transform: scale(1.75);
    margin-right: 5rem;
  }

  @media (max-width: 768px) {
    .linkedin-logo {
      margin-right: 0;
    }
  }

  p{
    letter-spacing: 0.2rem;
    display: flex;
    align-items: center;

    gap: 0.5rem;
    img{
      width: 2.6rem;
      animation: spinning 5s infinite linear;
    }
  }

  .fill{
    flex-grow: 1;
  }

  .social-media{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin-left: 12rem;
    img,span{
      font-size: 3rem;
      width: 3rem;
    }
  }

  @keyframes spinning {
    0%{
      transform: rotate(0);
    }
    100%{
      transform: rotate(360deg);
    }
  }

  @media(max-width: 800px){
    padding: 4rem 10rem;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
    .social-media{margin-left: 0rem;}
  }

  @media(max-width: 600px){
    padding: 4rem 1rem;
    p{
      font-size: 1.2rem;
    }
  }
`