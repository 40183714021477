import styled from "styled-components";

export const Container = styled.section`
  margin-top: 16rem;
  margin-left: 2%;
  margin-right: 2%;

  header{
    text-align: center;
    margin-bottom: 5rem;
    h2{
      font-size: 2.5rem;
    }
  }

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto 1fr 1fr 1;
    gap: 0px 5%;
    grid-auto-flow: row;
    grid-template-areas:
      "header header header"
      "person-1-name person-2-name person-3-name"
      "person-1-title person-2-title person-3-title"
      "person-1-mail person-2-mail person-3-mail"
      "person-1-bio person-2-bio person-3-bio";
  
  .header { grid-area: header; }

  .person-1-name { grid-area: person-1-name; }
  
  .person-1-title { grid-area: person-1-title; }
  
  .person-1-mail { grid-area: person-1-mail; }
  
  .person-1-bio { grid-area: person-1-bio; }
  
  .person-2-name { grid-area: person-2-name; }
  
  .person-2-title { grid-area: person-2-title; }
  
  .person-2-mail { grid-area: person-2-mail; }
  
  .person-2-bio { grid-area: person-2-bio; }
  
  .person-3-name { grid-area: person-3-name; }
  
  .person-3-title { grid-area: person-3-title; }
  
  .person-3-mail { grid-area: person-3-mail; }
  
  .person-3-bio { grid-area: person-3-bio; }
  
  .contact{
    color: #FFF;
    background-color: #016fb9;
    border-radius: 1.4rem;
    padding: 1.6rem 2.8rem;
    a{
      color: #ffffff;
      font-weight: 500;
    }
    &:hover{
      box-shadow: 0 0 11px rgba(33,33,33,.8); 
      transition: 0.5s;
      background: linear-gradient(45deg, rgba(1,111,185,1) 0%, rgba(35,206,107,1) 100%);
    }
  }

  .person-1-name{
    text-align: center;
    font-weight: 500;
    font-size: 2.5rem;
    margin-bottom: 1rem;
    text-decoration: underline;
  }

  .person-1-title{
    margin-bottom: 0.5rem;
    text-align: center;
  }

  .person-1-mail{
    text-align: center;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .person-1-bio{
    text-align: justify;
  }

  .person-2-name{
    text-align: center;
    font-weight: 500;
    font-size: 2.5rem;
    margin-bottom: 1rem;
    text-decoration: underline;
  }

  .person-2-title{
    margin-bottom: 0.5rem;
    text-align: center;
  }

  .person-2-mail{
    text-align: center;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .person-2-bio{
    text-align: justify;
  }

  .person-3-name{
    text-align: center;
    font-weight: 500;
    font-size: 2.5rem;
    margin-bottom: 1rem;
    text-decoration: underline;
  }

  .person-3-title{
    margin-bottom: 0.5rem;
    text-align: center;
  }

  .person-3-mail{
    text-align: center;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  
  .person-3-bio{
    text-align: justify;
  }

  @media(max-width: 960px){
    margin-left: 2rem;
    margin-right: 2rem;
    gap: 10px 0%;
    justify-content: center !important;
    grid-auto-flow: column;
    grid-template-columns: 1fr;
  }

  span { white-space: nowrap; hyphens: none; }
`