import { Container } from "./styles";
import { Trans, useTranslation } from "react-i18next";
import { Parallax, Background } from 'react-parallax';
import { LinkText } from "../helpers/LinkText";

export function Portfolio() {
  const { t } = useTranslation();

  const link1 = 'https://www.amundi.com';
  const link2 = 'https://www.cpr-am.com';
  const link3 = 'https://www.montpensier.com';

  return (
    <Parallax strength={300}>
      <Background className="heroBackground">
        <img src={process.env.PUBLIC_URL + '/bg_services.jpg'} style={{ objectFit: "fill", backgroundSize: "cover" }} alt="Buildings with bright sky" />
      </Background>

      <Container id="whatweoffer">
        <h2>{t("portfolio_title")}</h2>

        <div className="projects">

          <div className="project">
            <header>
              <h3>
                {t("portfolio_box1_1")}
              </h3>
            </header>
            <div className="body">
              {/* <p>{t("portfolio_box1_2")}</p> */}
              <Trans
              i18nKey="portfolio_box1_2"
              components={{
                link1: <LinkText to={link1} title="Amundi" />,
                br: <br />,
                span: <span/>
              }}
            />
            </div>
              <br/>
            <h6>* Source: Amundi corporate site – 31 December 2023</h6>
            {/* <footer>
            <ul className="tech-list">
              <li>Node</li>
              <li>Express</li>
              <li>MySql</li>
              <li>Ejs</li>
            </ul>
            </footer>
             */}
          </div>

          <div className="project">
            <header>
              <h3>
                {t("portfolio_box2_1")}
              </h3>
            </header>
            <div className="body">
              {/* <p>{t("portfolio_box2_2")}</p>
              <p>{t("portfolio_box2_3")}</p>
              <p>{t("portfolio_box2_4")}</p> */}
            <Trans
              i18nKey="portfolio_box2_2"
              components={{
                link2: <LinkText to={link2} title="CPR AM" />,
                br: <br />,
                span: <span/>
              }}
            />
            </div>
            {/* <footer> </footer> */}
          </div>

          <div className="project">
            <header>
              <h3>
                {t("portfolio_box3_1")}
              </h3>
            </header>
            <div className="body">
              {/* <p>{t("portfolio_box3_2")}</p>
              <p>{t("portfolio_box3_3")}</p>
              <p>{t("portfolio_box3_4")}</p>
              <p>{t("portfolio_box3_5")}</p> */}
              <Trans
                i18nKey="portfolio_box3_2" 
                components={{
                  link3: <LinkText to={link3} title="Montpensier" />,
                  br: <br />,
                  span: <span/>
                }}
              />
            </div>
            {/* <footer> </footer> */}
          </div>

        </div>
      </Container>
    </Parallax>
  );
}