import { Container } from "./styles";
import { useTranslation } from "react-i18next";

export function Cookies() {
  const { t } = useTranslation();
  return (
    <Container id="Cookies">
      <div className="about-text">

        <h2>{t("cookies_title")}</h2>
        <h3>{t("cookies_sub_title")}</h3>

        <p>
          {t("cookies_preface")}
        </p>
        <p>
          {t("cookies_date")}
        </p>
        <p>
          {t("cookies_1")}
        </p>
        <p>
          {t("cookies_2")}
        </p>
        <p>
          {t("cookies_3")}
        </p>
        <p>
          {t("cookies_4")}
        </p>
        <p>
          {t("cookies_5")}
        </p>
      </div>
    </Container>
  )
}
