import { HeroBody } from "../HeroBody/HeroBody"
import { HeroBoxes } from "../HeroBoxes/HeroBoxes"

export function Hero() {
  return (
    <>
      <img src={process.env.PUBLIC_URL + '/bg_home.webp'} className="bgimg" style={{ width: '100%', overflowX: 'hidden', marginTop: '4rem', marginBottom: '-5rem' }} alt="Building with bright sky" />
      <HeroBody></HeroBody>
      <HeroBoxes></HeroBoxes>
    </>
  )
}