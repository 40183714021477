import { Container } from "./styles";
import { useTranslation } from "react-i18next";

export function TermsOfUse() {
  const { t } = useTranslation();
  return (
    <Container id="TermsOfUse">
      <div className="about-text">
        <h2>{t("tou_title")}</h2>
        <h3>{t("tou_sub_title")}</h3>

        <p>
          {t("tou_date")}
        </p>
        <p>
          {t("tou_txt_1")}
        </p>
        <p>
          {t("tou_txt_2")}
        </p>
        <p>
          {t("tou_txt_3")}
        </p>
        <p>
          {t("tou_txt_4")}
        </p>
        <p>
          {t("tou_txt_5")}
        </p>
        <p>
          {t("tou_txt_6")}
        </p>
        <p>
          {t("tou_txt_7")}
        </p>
        <p>
          {t("tou_txt_8")}
        </p>
        <p>
          {t("tou_txt_9")}
        </p>
      </div>
    </Container>
  )
}