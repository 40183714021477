import { LinkText } from "../helpers/LinkText";
import { Container } from "./styles"
import { Trans, useTranslation } from "react-i18next";

export function HeroBody() {

  const { t } = useTranslation();

  const { i18n } = useTranslation();

  const link1 = 'https://www.amundi.com';
  const link2 = 'https://www.cpr-am.com';
  const link3 = 'https://www.montpensier.com';

  return (
    <Container>
      <div className="hero-intro" id="about-nextgenis">
        <section className="text">
        
        <Trans
          i18nKey="hero_text_1"
          components={{
            link1: <LinkText to={link1} title="Amundi" />,
            link2: <LinkText to={link2} title="CPR-AM" />,
            link3: <LinkText to={link3} title="Montpensier" />
          }}
        />

        </section>

      </div>
    </Container>
  )
}