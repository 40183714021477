import { Container } from "./styles";
import { Form } from "../Form/Form";
import { useTranslation } from "react-i18next";

export function Contact() {
    const { t } = useTranslation();
    return (
        <>
            <Container>
                <header className="header">
                    <h2>{t("contact_head")} <span>+30 210 371 3900</span></h2>
                </header>
            </Container>
            <Form></Form>
        </>
    )
}