import styled from "styled-components";

export const Container = styled.section`
  margin-top: 12rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-bottom: 12rem;
  text-align: justify;
  text-justify: inter-word;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10rem;
  margin-right: 10rem;

  h2{
    display: inline-block;
    margin-bottom: 2rem;
    border-bottom: 0.2rem solid var(--blue);
  }

  h3{
    margin-top: 2rem;
  }

  p{
    font-size: 1.8rem;
    letter-spacing: 0.1rem;
    font-weight: 500;
    margin-top: 2rem;
  }

  @media (max-width: 960px){
    display: block;
    text-align: center;
  }
`