import { Container } from "./styles"
import { Hero } from "../Hero/Hero"

export function Main() {
  return (
    <>
    <Hero></Hero>
    {/* <div className="videoFeed" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: "15px"}}>
      <iframe width="840" height="472" src="https://www.youtube.com/embed/q0C9IQioPaY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div> */}

    {/* <div style={{padding:"56.25% 0 0 0", position:"relative", marginBottom: "15px"}}><iframe src="https://player.vimeo.com/video/783249687?h=c14024c859&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{position: "absolute", top:"0",left:"0",width:"100%",height:"100%"}} title="Presentation 08-12-2022"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script> */}
    </>
  );
}