import styled from "styled-components";

export const Container = styled.section`

.hero-intro{
  margin-top: -auto !important;
}

.text{
  margin: 0% 5% 2.5% 5% !important;
  font-size: 2rem;
  text-align: center;
  }
}
`