import { Container } from './styles'
import { HashLink } from 'react-router-hash-link'
import { useTranslation } from "react-i18next";

import nextGenisLogo from '../../assets/nextGenisLogoFull.png'
import linkedin from '../../assets/linkedin.svg'

export function Footer() {
  const { t } = useTranslation();
  return (
    <Container className="footer">
      <HashLink smooth to="/#home" className="logo">
        <img src={nextGenisLogo} className='c-logo' alt='company logo'></img>
      </HashLink>

      <div>
        <p>
          <small>
            {t("footer_ltxt_1")}<br />
            {t("footer_ltxt_2")}<br />
            {t("footer_ltxt_3")}<br />
            {t("footer_ltxt_4")}<br />
            {t("footer_ltxt_5")}<br />
            {t("footer_ltxt_6")}<br />
          </small>
          <br />
          <br />
          <br />
        </p>
      </div>

      <div className='fill'></div>

      <div className="social-media">
        <a
          href="https://www.linkedin.com/company/90829766"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={linkedin} className="linkedin-logo" alt="LinkedIn Logo" />
        </a>
      </div>

      <div className='fill'></div>

      <div>
        <ul>
          <li>
            <HashLink smooth to="/TermsOfUse" className="pages">
              <span>{t("footer_rtxt_1")}</span>
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="/PrivacyPolicy" className="pages">
              <span>{t("footer_rtxt_2")}</span>
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="/Cookies" className="pages">
              <span>{t("footer_rtxt_3")}</span>
            </HashLink>
          </li>
        </ul>
      </div>
    </Container>
  )
}
