import { Container } from "./styles";
import { Trans, useTranslation } from "react-i18next";
import { Parallax, Background } from 'react-parallax';
import { LinkText } from "../helpers/LinkText";

export function HeroBoxes() {
  const { t } = useTranslation();

  const link1 = 'https://www.amundi.com';
  const link2 = 'https://www.cpr-am.com';

  return (
    <Parallax strength={300}>
      <Background className="heroBackground">
        <img src={process.env.PUBLIC_URL + '/bg_services.jpg'} style={{ objectFit: "fill", backgroundSize: "cover" }} alt="Buildings with bright sky" />
      </Background>

      <Container id="whatweoffer">
        <h2>{t("portfolio_box_hero_title")}</h2>

        <div className="projects">
          <div className="project">
            <header>
              <h3>
                {t("portfolio_box_hero1_1")}
              </h3>
            </header>

            <div className="body">

              <Trans
              i18nKey="portfolio_box_hero1_2"
              components={{
                br: <br />,
                span: <span/>
              }}
            />

            <br/>

              <Trans
              i18nKey="portfolio_box_hero1_3"
              components={{
                br: <br />,
                span: <span/>
              }}
            />

          <br/>

              <Trans
              i18nKey="portfolio_box_hero1_4"
              components={{
                br: <br />,
                span: <span/>
              }}
            />
            </div>
          </div>

          <div className="project">
            <header>
              <h3>
                {t("portfolio_box_hero2_1")}
              </h3>
            </header>

            <div className="body">

              <Trans
              i18nKey="portfolio_box_hero2_2"
              components={{
                br: <br />,
                span: <span/>
              }}
            />

            <br/>

              <Trans
              i18nKey="portfolio_box_hero2_3"
              components={{
                br: <br />,
                span: <span/>
              }}
            />

          <br/>

              <Trans
              i18nKey="portfolio_box_hero2_4"
              components={{
                br: <br />,
                span: <span/>
              }}
            />

            <br/>

              <Trans
              i18nKey="portfolio_box_hero2_5"
              components={{
                br: <br />,
                span: <span/>
              }}
            />
            </div>
          </div>

        </div>



      </Container>
    </Parallax>
  );
}